@import "../../assets/styles/variables";
@import "../../assets/styles/mixin";
* {
  margin: 0;
}

.headerLogo {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  img {
    width: 48px;
    height: 48px;
  }
  p {
    font-weight: 600;
    font-size: $font-size-lg-medium;
    color: $white;
    padding-top: 8px;
  }
  @include sm {
    display: none;
  }
}

.details {
  display: none;
  font-size: $font-size-sm-base;
  padding-top: 2px;
  padding-left: 350px;
  p {
    font-weight: $font-size-sm-base;
    span {
      font-weight: $font-weight-semibold;
    }
  }
  @include sm {
    display: none;
    // display: flex;
    padding-left: 0px;
    flex-direction: column;
    align-items: end;
    text-align: end;
    padding-top: 30px;
    height: 0px;
  }
}

.dropDown {
  @extend .details;
  @include sm {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    // margin-right: 25px;
    padding: 0;
    height: 60px;
    padding-top: 8px;
  }
}
.detailsRole {
  padding-right: 8px;
  // margin-top: 5px;
  border-right: 1px solid;
  b {
    padding-left: 6px;
    font-size: $font-size-sm-base;
    font-weight: $font-weight-normal;
  }
  @include sm {
    border: none;
    padding: 0;
  }
}
.assignment {
  padding-left: 8px;
  span {
    padding-right: 6px;
  }
  @include sm {
    display: flex;
    flex-direction: row;
    span {
      padding-right: 0px;
    }
  }
}
// .greeting{
//     font-size: $font-size-md-xtraLarge;
//     font-weight: $font-weight-bold;
//     line-height: 120%;
//     display: flex;
//     img{
//         display: none;
//     }
//     @include sm{
//         gap: 12px;
//         img{
//             display: inline-block;
//             width: 13px;
//             height: 13px;
//             cursor: pointer;
//             padding-top: 5px;
//             transition: 0.3s ease;
//         }
//     }
// }
.rotateImg {
  padding-top: 5px;
  transform: rotate(180deg);
}

.header {
  width: 100%;
  background-color: $primaryBlue;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.headertop {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $secondryBlue;
  @include sm {
    border-bottom: none;
    height: 0;
  }
}
.dttReports {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $white;
  margin-left: 160px;
  .backArrow {
    width: 24px;
    height: 24px;
    padding-right: -10px;
  }
  .ucubeText {
    padding-bottom: 5px;
  }
  @include sm {
    display: none;
  }
}
.navlinks {
  display: none;
  flex-direction: row;
  align-items: center;
  color: $white;
  gap: 16px;
  margin-right: 180px;
  cursor: pointer;
  p {
    font-size: $font-size-sm-base;
    font-weight: $font-weight-normal;
    margin-top: 12px !important;
    @include sm {
      margin-top: 0px !important;
    }
  }
  span {
    margin-left: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    // margin-bottom: 10px;
    cursor: pointer;
    p {
      font-weight: $font-weight-normal;
      font-size: $font-size-sm-base;
      // margin-top: 12px !important;
    }
  }
  @include sm {
    position: absolute;
    left: -200%;
    p {
      margin-top: 0 !important;
    }
  }
}
.menuSide {
  @extend .navlinks;
  @include sm {
    position: fixed;
    left: 0;
    top: 150px;
    height: 100%;
    width: 100%;
    background-color: $white;
    color: $black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 999;
  }
}
.headerbottom {
  width: 100%;
  // height: 30px;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 170px;
  background-color: $primaryBlue;
  padding-bottom: 16px;
  p {
    margin-right: 10px;
    font-weight: $font-weight-semibold;
    font-size: $font-size-sm-base;
    color: $white;
    margin-bottom: 5px !important;
  }
  .hamMenu {
    display: none;
  }
  @include sm {
    // height: 60px;
    // justify-content: space-between;
    padding-right: 12px;
    padding-bottom: 0px;
    .hamMenu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      position: absolute;
      top: 22px;
      left: 16px;
    }
    .hamMenu div {
      width: 20px;
      height: 2px;
      border-radius: 2px;
      background-color: $white;
    }
    p {
      margin-right: 16px;
      font-size: $font-size-sm-base;
    }
  }
}
.headerSidebar {
  @extend .headerbottom;
  @include sm {
    position: relative;
    background-color: $primaryBlue;
    display: flex;
    flex-direction: column;
    height: 170px;
    width: 100%;
    justify-content: space-around;
    div,
    p {
      width: 100%;
      display: flex;
    }
    .hamMenu {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-left: 0;
      position: absolute;
      top: 22px;
      right: 22px;
      left: initial;
    }
    p {
      display: none;
    }
  }
}
.menuIcons {
  display: none;
  @include sm {
    display: block;
  }
}
.menuSide {
  @include sm {
    border-radius: 24px 24px 0px 0px;
    padding-top: 32px;
    div,
    span {
      display: flex;
      flex-direction: row;
      gap: 16px;
      height: 64px;
      align-items: center;
      text-align: center;
      margin-left: 16px;
    }
  }
}
.logout {
  margin-top: 10px;
  @include sm {
    display: none;
  }
}
.logout_blue {
  display: none;
  @include sm {
    display: block;
  }
}

.header1 {
  background-color: #275ce6;
  height: 40px;
  font-size: 20px;
  color: #ffffff;
  font-weight: 900;
  /* margin-right: 180px; */
  position: relative;
  display: flex;
  justify-content: end;
  padding-right: 180px;
  padding-top: 10px !important;
  img {
    display: none;
  }
  @include sm {
    // display: none;
    gap: 8px;
    font-size: 16px;
    padding-right: 24px;
    height: initial;
    padding-top: 22px !important;
    img {
      display: initial;
      margin-top: 8px;
    }
  }
}

.underLine {
  text-decoration: underline;
}
.bsaDropdown {
  border: none;
  background-color: $primaryBlue;
  color: $white;
  overflow: hidden !important;
  outline: none;
  cursor: pointer;
  // width: initial;
  option {
    // min-width: 80px;
    cursor: pointer;
  }
  :focus {
    border: none;
  }
  :focus-within {
    border: none;
  }
  @include sm {
    width: initial;
    option {
      min-width: 40px;
    }
  }
}
.mobileViewAdjust {
  @include sm {
    background-color: $primaryBlue;
    padding-bottom: 10px;
  }
}
.menuOpenView {
  @include sm {
    background-color: $primaryBlue;
    padding-bottom: 10px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
  }
}
.noneHeader {
  @extend .header1;
  @include sm {
    position: absolute;
    top: 60px;
    left: 16px;
    z-index: 10;
    img {
      display: none;
    }
  }
}
.backArrow {
  width: 24px;
  height: 24px;
}
:root {
  --rt-opacity: 1 !important;
}
.country_popup {
  margin-right: 180px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  .countryName {
    border-right: 1px solid #5588f7;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-right: 16px;
    .name {
      font-size: 20px !important;
      font-weight: 400 !important;
      margin-bottom: 5px !important;
      color: $white;
    }

    @include sm {
      border-right: 0px;
    }
    .countryLogo {
      // border: 1px solid white;
      border-radius: 50%;
    }
  }
  .profileIcon {
    cursor: pointer;
  }
  .popupModal {
    background-color: $white !important;
    border-radius: 8px !important;
    padding: 16px !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 1;
    .popup {
      width: 344px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      cursor: auto !important;
      .popupDetails {
        padding: 18px 8px;
        width: 100%;
        border-radius: 8px;
        background-color: $timedetailsGrey;
        color: $darkGreyText;
        h4 {
          font-size: $font-size-md-xtraLarge;
          font-weight: $font-weight-semibold;
          margin-bottom: 8px;
          line-height: 120%;
        }
        p {
          font-size: $font-size-sm-base;
          font-weight: $font-weight-normal;
          line-height: 120%;
        }
        .userDetailsPopup {
          padding-top: 24px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
        .popup_role {
          display: flex;
          flex-direction: row;
          gap: 8px;
          font-weight: $font-weight-semibold;
          p {
            line-height: normal;
          }
          .popupDetails_bsaDropdown {
            display: flex;
            gap: 6px;
            border-bottom: 1px solid $primaryBlue;
            background-color: $timedetailsGrey;
            color: $primaryBlue;
          }
        }
      }
      .popupLogout {
        display: flex;
        flex-direction: row;
        padding: 10px;
        cursor: pointer;
        width: 100%;
        p {
          color: $primaryBlue;
          font-weight: $font-weight-semibold;
          font-size: $font-size-sm-base;
          padding-left: 8px;
        }
      }
    }
  }
  @include sm {
    display: none;
  }
}
.dropupdowntrue {
  transform: rotate(180deg);
}
