@import "./variables";

// Font family

@font-face {
  font-family: "Unilever Shilling";
  src: url("../fonts/UnileverShilling.ttf")format("truetype");
  font-weight: normal;
  font-style: normal;
}


// Font families and font-weights

@mixin volteMedium {
  font-family: "Unilever Shilling", sans-serif;
}

@mixin volteSemibold {
  font-family: "Unilever Shilling", sans-serif;
}

@mixin sm {
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    @content;
  }
}

@mixin md {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin sm-min {
  @media only screen and (min-width: 992px) {
    @content;
  }
}

@mixin lg {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}

@mixin xl-min {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}

@mixin xl {
  @media only screen and (min-width: 1280px) {
    @content;
  }
}

@mixin xxl {
  @media only screen and (min-width: 1440px) {
    @content;
  }
}

@mixin sm-tab {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin ipad {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    @content;
  }
}

