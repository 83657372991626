.Toastify__toast {
  background-color: #59b876 !important;
  width: 400px;
  top: 20px;
  right: 80px;
}

.Toastify__toast--success {
  background-color: #59b876 !important;
}
 
.Toastify__toast--error {
  background-color: #D52F3D !important;
}
 
.Toastify__zoom-enter {
  display: none !important;
}

.Toastify__progress-bar {
  display: none;
}

.Toastify__close-button {
  align-self: center;
}

.Toastify__toast-body {
  font-size: 16px !important;
  /* padding: 20px !important; */
  color: white !important;
  margin-left: -10px;
  display: flex;
  align-items: center;
}

@media (max-width: 480px) {
  .Toastify__toast {
    width: 380px;
    top: 0;
    right: 0;
  }
}
