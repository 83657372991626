.toast-container {
  background-color: #59b876;
}

.toast-content {
  display: flex;
  align-items: center;
}

.toast-icon {
  margin: 10px;
}

.toast-message {
  margin: 10px 0;
}

.close-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
