.logo {
  margin: auto;
  display: block;
  position: fixed;
  width: 49%;
  width: 10%;
  height: 10%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; // background-color: rgba(0, 0, 0, 0.1);    z-index: 900;

  .demo {
    width: 50px;
  }
}
