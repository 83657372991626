@import "../../../assets/styles/variables";
@import "../../../assets//styles/mixin";

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  transition: opacity 0.3s ease;

  &.mobile_overlay {
    //
  }

  .modal {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    width: 500px;
    max-width: 100%;
    padding: 20px;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease height 0.3s ease;
    transform: translateY(0);

    &.mobile_modal {
      position: absolute;
      bottom: 0;
      width: 600px;
      border-radius: 0px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      .modal_header {
        display: flex;
        justify-content: center;
        // margin-bottom: 20px;
        color: #f2f2f2;

        .close_button {
          cursor: pointer;
          width: 80px;
          height: 16px;
        }
      }

      .modal_content {
        .fileupload {
          .message {
            font-size: $font-size-sm-small;
            font-weight: $font-weight-normal;
          }
          .uploadcontainer {
            height: 129px;
            top: 29px;
            left: 8px;
            border: 1px dashed gray;
            border-radius: 8px;

            .uploading {
              margin-top: 40px;
              img {
                margin-left: 150px;
              }
              p {
                margin-left: 130px;
              }
            }

            .error_container {
            }

            .logo_container {
              img {
                margin-top: 10px;
                margin-left: 10px;
              }
              .textcontainer {
                .p1 .p2 {
                  font-size: $font-size-sm-small;
                  font-weight: $font-weight-semibold;
                }
              }
            }

            .browse_button {
              padding: 9px 5px;
              margin-left: 70%;
              margin-top: 10px;
            }
          }
        }
      }
    }

    .modal_header {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;

      .close_button {
        cursor: pointer;
        width: 50px;
        height: 16px;
      }
    }

    .modal_content {
      .modal_c {
        margin-bottom: 20px;

        .modal_c_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 5px;

          h2 {
            font-size: $font-size-sm-base;
            font-weight: $font-weight-semibold;
            margin-bottom: 10px;
            color: black;
          }

          p {
            font-size: $font-size-sm-base;
            font-weight: $font-weight-normal;
            color: $primaryBlue;
            margin-top: 10px;
          }
        }
      }

      .fileupload {
        margin-top: 50px;

        .message {
          color: #030d14;
          margin-left: 0px;
          margin-top: -15px;
        }

        .uploadcontainer {
          height: 100px;
          // width: 400px;
          margin-top: 15px;
          border: 1px dashed gray;
          border-radius: 9px;

          .uploading {
            margin-top: 18px;

            img {
              height: 24px;
              width: 24px;
              margin-left: 220px;
            }

            p {
              font-size: 12px;
              margin-left: 1px;
              margin-left: 200px;
            }
          }

          .selected_file {
            margin: 10px 10px 10px 10px;
            img {
              height: 24px;
              width: 24px;
            }

            p {
              font-size: 12px;
              margin-bottom: -20px;
            }
          }

          .error_container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 10px;
            margin-left: 15px;

            img {
              height: 24px;
              width: 24px;
            }

            .caution {
              color: red;
              font-size: 14px;
              margin-bottom: 0px !important;
            }

            .error_message {
              font-size: 14px;
            }
          }
          .logo_container {
            display: flex;
            flex-direction: column;
            margin: 5px 10px 0px 5px;

            img {
              height: 72px;
              width: 72px;
            }

            .textcontainer {
              margin-top: -55px;
              margin-left: 90px;

              .p1 {
                margin-bottom: -5px;
                font-size: 12px;
              }
              .p2 {
                font-size: 12px;
              }
            }
          }

          .browse_button {
            padding: 5px 25px;
            background-color: $white;
            color: $buttonbgBlue;
            font-size: 12px;
            border: none;
            border-radius: 100px;
            margin-left: 75%;
            margin-top: -70px;
          }
        }
      }

      .button_container {
        margin-top: 40px;

        button {
          display: block;
          margin-top: 10px;
          padding: 8px 30px;
          border-radius: 999px;
        }

        button:first-child {
          float: left;
          border: none;
          margin-right: 10px;
          background-color: $white;
        }

        // button:last-child {
        //   float: right;
        //   border: none;
        //   background-color: $primaryBlue;
        //   color: $white;
        // }

        .enable {
          float: right;
          border: none;
          background-color: $primaryBlue;
          color: $white;
          cursor: pointer;
        }

        .disable {
          float: right;
          border: none;
          background-color: #cccccc;
          cursor: none;
          color: $white;
        }
      }
    }
  }
}

.download {
  background-color: transparent;
  border: none;
  color: $primaryBlue;
}

.blurBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  transition: opacity 0.3s ease;
}
.toast-container {
  background-color: #59b876;
}

.toast-content {
  display: flex;
  align-items: center;
}

.toast-icon {
  margin: 10px;
}

.toast-message {
  margin: 10px 0px;
}

.close-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
