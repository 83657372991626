@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixin";

.mainHeader {
  position: fixed;
  z-index: 9999;
  width: 100%;
  top: 0px;
  display: contents;
}

.header {
  width: 100%;
  background-color: $primaryBlue;
}

.countryName {
  border-right: 1px solid #5588f7;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 16px;
  .name {
    font-size: 20px !important;
    font-weight: 400 !important;
    margin-bottom: 5px !important;
  }

  @include sm {
    border-right: 0px;
  }

  .countryLogo {
    // border: 1px solid white;
    border-radius: 50%;
  }
}

.countryNameMbl {
  border-right: 1px solid #5588f7;
  display: flex;
  align-items: center;
  justify-self: end;
  gap: 8px;
  padding-right: 16px;
  .name {
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-bottom: 5px !important;
  }

  @include sm {
    border-right: 0px;
  }

  .countryLogo {
    // border: 1px solid white;
    border-radius: 50%;
  }
}

.headertop {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $secondryBlue;
  @include sm {
    border-bottom: none;
    height: 0;
  }
}
.dttReports {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $white;
  cursor: pointer;
  // gap: 8px;
  margin-left: 174px;
  .unileverLogo {
    width: 49px;
    height: 49px;
  }
  .backArrow {
    width: 24px;
    height: 24px;
  }
  p {
    width: 83px;
    font-size: $font-size-md-large;
    font-weight: $font-weight-normal;
    line-height: 115%;
    margin-bottom: 8px !important;
  }

  @include sm {
    display: none;
  }
}
.navlinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $white;
  margin-right: 180px;
  gap: 14px;
  cursor: pointer;
  p {
    font-size: $font-size-sm-base;
    font-weight: $font-weight-normal;
    margin-bottom: 0px;
  }
  span {
    display: none;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    p {
      // font-weight: $font-weight-semibold;
      font-weight: $font-weight-normal;
      font-size: $font-size-sm-base;
    }
  }
  // img {
  //   margin-bottom: 10px !important;
  // }
  @include sm {
    position: absolute;
    left: -200%;
  }
}
.profileIcon {
  margin-left: 20px;
  @include sm {
    display: none !important;
  }
}
:root {
  --rt-opacity: 1 !important;
}
.popupModal {
  background-color: $white !important;
  border-radius: 8px !important;
  padding: 16px !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
  .popup {
    width: 344px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    cursor: auto !important;
    .popupDetails {
      padding: 18px 8px;
      width: 100%;
      border-radius: 8px;
      background-color: $timedetailsGrey;
      h4 {
        font-size: $font-size-md-xtraLarge;
        font-weight: $font-weight-semibold;
        margin-bottom: 8px;
        color: $darkGreyText;
        line-height: 120%;
      }
      p {
        font-size: $font-size-sm-base;
        font-weight: $font-weight-normal;
        color: $darkGreyText;
        line-height: 120%;
      }
    }
    .dtAccess {
      display: flex;
      flex-direction: row;
      gap: 10px;
      width: 100%;
      margin-top: 1rem;
      color: $darkGreyText;
      .title {
        text-wrap: nowrap;
        font-weight: $font-weight-semibold;
      }
      .display {
        max-width: fit-content;
      }
      .toggle {
        display: flex;
        align-items: end;
        color: $primaryBlue;
        cursor: pointer;
      }
    }
    .popupLogout {
      display: flex;
      flex-direction: row;
      padding: 10px;
      cursor: pointer;
      width: 100%;
      p {
        color: $primaryBlue;
        font-weight: $font-weight-semibold;
        font-size: $font-size-sm-base;
        padding-left: 8px;
      }
    }
  }
  @include sm {
    display: none;
  }
}
.sideMenuOptions {
  min-height: 64px;
  display: none;
}

.menuSide {
  @extend .navlinks;
  @include sm {
    position: absolute;
    left: 0;
    bottom: 0px;
    height: 100%;
    width: 100%;
    background-color: $white;
    color: $black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 999;
  }
}
.userName {
  font-size: 20px !important;
  font-weight: 900 !important;
  @include sm {
    font-size: 16px !important;
  }
}
.username {
  margin-right: 180px;
  cursor: pointer;

  @include sm {
    margin-right: 0px;
    padding-bottom: 4px !important;
  }

  .userName {
    font-size: 20px !important;
    font-weight: 900 !important;
    @include sm {
      font-size: 16px !important;
    }
  }
}
.headerbottom {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  // padding-left: 180px;
  align-items: center;
  background-color: $primaryBlue;
  p {
    margin-bottom: 0px !important;
    font-weight: $font-weight-normal;
    font-size: $font-size-sm-base;
    color: $white;
  }
  .hamMenu {
    display: none;
  }
  @include sm {
    height: 60px;
    justify-content: flex-end;
    .hamMenu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      margin-left: 18px;
      margin-right: auto;
    }
    .hamMenu div {
      width: 20px;
      height: 2px;
      border-radius: 2px;
      background-color: $white;
    }
    p {
      margin-right: 16px;
      font-size: $font-size-md-medium;
      margin-bottom: 0 !important;
    }
  }
}
.headerSidebar {
  @extend .headerbottom;
  @include sm {
    position: fixed;
    background-color: $primaryBlue;
    display: flex;
    flex-direction: column;
    height: 170px;
    width: 100%;
    justify-content: space-around;
    z-index: 999;
    div,
    p {
      width: 100%;
      display: flex;
    }
    .hamMenu {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-left: 0;
      position: absolute;
      top: 22px;
      right: 22px;
    }
    p {
      font-size: $font-size-md-medium;
      position: absolute;
      bottom: 60px;
      left: 16px;
      width: auto;
    }
    .countryNameMbl {
      display: none;
    }
  }
}
.menuIcons {
  display: none;
  @include sm {
    display: block;
  }
}
.menuSide {
  @include sm {
    border-radius: 24px 24px 0px 0px;
    padding-top: 32px;
    position: fixed;
    height: calc(100vh - 150px);
    padding-left: 16px;
    overflow: scroll;
    z-index: 2000;
    div,
    span {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
      text-align: center;
      width: 100%;
    }
  }
}
.logout {
  @include sm {
    display: none;
  }
}
.logout_blue {
  display: none;
  @include sm {
    display: block;
  }
}

.underLine {
  // text-decoration: underline;
}

.userHeaderMbl {
  display: none;

  @include sm {
    display: block;
  }
}

.userHeaderDesktop {
  display: block;
  margin-left: 180px;
  cursor: pointer;

  @include sm {
    display: none;
  }
}

.headerbottom {
  .backButton {
    p {
      font-size: 16px;
    }

    @include sm {
      display: none;
    }
  }

  // @include sm {
  //   display: none;
  // }
}

.dropdownConatiner {
  display: flex;
  gap: 16px;
  height: 100%;
  padding: 0px 8px;
  align-items: center;
  margin-left: 180px;

  .dropdownWrapper {
    display: flex;
    height: 100%;
  }

  @include sm {
    flex-direction: column !important;
    display: none;
  }

  .dropdownselected {
    background: #5588f7;
    border-bottom: 3px solid white;
    span {
      font-weight: 600;
    }
  }

  .dropdown {
    position: relative;
    height: 100%;
    align-items: center;
    display: flex;

    @include sm {
      flex-direction: column !important;
    }

    span {
      color: white;
      font-size: 14px;
      padding: 0px 8px;

      @include sm {
        color: black;
      }
    }
    .dropdownBody {
      position: absolute;
      z-index: 1;
      background: #275ce6;
      border-radius: 8px;
      padding: 1rem 0px;
      display: flex;
      width: 322px;
      flex-direction: column;
      align-items: flex-start;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      top: 90%;
      z-index: 9999;

      .dropdownOptions:hover {
        background-color: #5588f7;
        font-weight: 600;
      }
      .dropdownOptions {
        font-size: 14px;
        font-weight: 400;
        color: white;
        width: 100%;
        padding: 8px 1rem;
      }
    }
  }
}

.SMOMobileView {
  min-height: 64px;
}

.dropdownMobile {
  flex-direction: column !important;
  gap: 0px !important;
  height: max-content;
  justify-content: flex-start;
  font-size: 14px !important;
  align-items: flex-start !important;
  width: 100%;

  .dropdownHeading {
    margin-left: 0px;
    min-height: 64px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .dropdownHeadingName {
      flex-basis: 75%;
    }
    .dropdownHeadingArrow {
      flex-basis: 25%;
      justify-content: center;
    }
  }

  .dropdownArrowOpen {
    transform: rotate(-90deg);
  }

  .dropdownArrowClose {
    transform: rotate(90deg);
  }

  span {
    width: 100%;
  }

  .dropdownBody {
    flex-direction: column !important;
    gap: 0px !important;
    overflow: scroll;
    width: calc(100% + 16px);
    margin-left: -16px !important;

    .dropdownOptions {
      margin: 0px !important;
      width: 100%;
      padding-left: calc(2.5rem + 16px);
      min-height: 48px;
      div {
        min-height: 48px;
      }
    }
    .dropdownSelected {
      background: #f3f7fd;
    }
  }
}
.navLinkSelected {
  height: 100%;
  padding: 0px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  // border-bottom: 6px solid $timedetailsGrey;
  // box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
  background-color: $secondryBlue !important;
  position: relative;
}
.navLinkSelected::after {
  content: "";
  border: 3px solid $timedetailsGrey;
  position: absolute;
  bottom: -2px;
  width: 102%;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}
.adminDropdown {
  position: relative;
  img:last-child {
    position: absolute;
    right: 16px;
    transform: rotate(90deg);
    transition: transform 0.1s ease-in-out;
  }
  .invertArrow {
    transform: rotate(-90deg) !important;
  }
}
.adminDropdownOptions {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: flex-start !important;
  padding-left: 38px;
  // gap: 32px !important;
  p {
    height: 40px;
    display: flex;
    align-items: center;
  }
}

.dropdownCountry {
  width: 170px;
  flex-shrink: 0;
  @include sm {
    width: 60px;
  }

  .dropdownControl {
    height: 100%;
    padding: 0px;
    background-color: $primaryBlue;
    border: none;
    outline: none;
    width: fit-content;
    cursor: pointer;
    &:hover {
      border: none;
      box-shadow: none;
      outline: none;
    }
    .countryName {
      border-right: 0;
      padding-right: 20px;
      .nameOption {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 5px;
      }
    }

    .dropdownArrow {
      div {
        padding: 0;
        color: white;
      }
    }

    .dropdownText {
      width: max-content;
      font-size: 90%;
      font-weight: 400;
      color: $white;
      font-family: "Unilever Shilling", sans-serif, -apple-system,
        BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue" !important;
    }
  }

  .seperator {
    display: none;
  }

  .menu {
    width: fit-content;
    overflow: hidden;
    border-radius: 5px;
    @include sm {
      position: absolute;
      right: 15%;
    }
  }

  .menuList {
    padding: 0;
    border-radius: 5px;
  }

  .menuOption {
    min-width: 100%;
    background-color: $white;
    width: fit-content;
    padding: 4px 4px;
    cursor: pointer;
    border-bottom: none;
    .countryName {
      border-right: 0;
      .nameOption {
        font-size: 16px;
        font-weight: 200;
        margin-bottom: 5px;
        color: $black;
      }
    }

    &:hover {
      background-color: $timedetailsGrey;
    }
    .selected {
      background-color: $timedetailsGrey;
    }
  }
}
