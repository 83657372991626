.popupDropDown-container .Dropdown-root {
  position: relative;
}

.popupDropDown-container .Dropdown-control {
  width: 120px;
  height: 30px;
  position: relative;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #275ce6;
  font-size: 14px;
  font-weight: 400;
  color: #275ce6;
  cursor: default;
  outline: none;
  padding: 0px;
  transition: all 200ms ease;
}

.popupDropDown-container .Dropdown-arrow {
  content: " ";
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 8.5px;
  width: 0;
  border: solid #275ce6;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.popupDropDown-container .is-open .Dropdown-arrow {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.popupDropDown-container .Dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: initial;
  overflow-wrap: break-word;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  text-align: left;
}

.popupDropDown-container .Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  text-transform: capitalize;
}

.popupDropDown-container .Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popupDropDown-container .Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.popupDropDown-container .Dropdown-option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.popupDropDown-container .Dropdown-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
}

.popupDropDown-container .Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.popupDropDown-container .Dropdown-placeholder {
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
  margin-left: 0px;
}

.bsaDropDownMobile .Dropdown-root {
  position: relative;
}

.bsaDropDownMobile .Dropdown-control {
  width: 120px;
  height: 30px;
  position: relative;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  cursor: default;
  outline: none;
  padding-left: 6px;
  transition: all 200ms ease;
}

.bsaDropDownMobile .arrow-closed,
.arrow-open {
  content: " ";
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 4px;
  top: 6.5px;
  width: 0;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.bsaDropDownMobile .arrow-open {
  content: " ";
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 4px;
  top: 9.5px;
  width: 0;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
}

.bsaDropDownMobile .Dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  /* top:100%; */
  width: initial;
  overflow-wrap: break-word;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  text-align: left;
}

.bsaDropDownMobile .Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  text-transform: capitalize;
}

.bsaDropDownMobile .Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bsaDropDownMobile .Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.bsaDropDownMobile .Dropdown-option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.bsaDropDownMobile .Dropdown-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
}

.bsaDropDownMobile .Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.bsaDropDownMobile .Dropdown-placeholder {
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
  margin-left: 0px;
  text-align: left;
}
