// Color codes here.
$headerBlue:#275CE6;
$headerBorder:#5588F7;
$primaryBlue:#275CE6;
$secondryBlue:#5588F7;
$white:#FFFFFF;
$tertiaryGrey:#676E72;
$errorRed: #D52F3D;
$progressRed:#E43263;
$errorRed: #D52F3D;
$progressGreen:#59B876;
$progressCircleGrey:#9496A0;
$black:#000000;
$timedetailsGrey:#F3F7FD;
$buttonbgBlue:#5588F7;
$inputBorder:#CCCFD0;
$buttonDisableBg:#E5E6E7;
$buttonDisableText:#999EA1;
$errorMessageRed:#FF5C83;
$darkGreyText:#333C43;
$tableHeader:#F6FCFF;
$LandingPageBlue:#1E49B9;
$secondary_lightish:#E1ECF5;
$opTabViewDisabled:#F2F3F3;
$otpGreyText:#B3B6C5;
$otpErrorRed:#D52F3D;
$oddTableRow: #f8f9fc;
$agTableRow: #f3f3f3;
$scrollbar: #888;
$scrollbarHover: #555;
$scrollbarTrack: #f1f1f1;
$dropdownListColor: #f0f0f0;
$dropdownShadow: #6123E70F;
$disableButton : #cccccc;
$progressContainer: #e0e0e0;
// Font Sizes
$font-size-xs-small: 10px;
$font-size-sm-small: 12px;
$font-size-sm-base: 14px;
$font-size-md-medium: 16px;
$font-size-md-large: 18px;
$font-size-md-xtraLarge: 20px;
$font-size-md-xLarge: 22px;
$font-size-lg-medium: 24px;
$font-size-xl-large: 32px;
$font-size-xl-xtraLarge: 40px;
$font-size-xl-medium: 28px;

// Font weights
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Font Styles
$font-style: 'Unilever Shilling';

// Line Height
$line-height-medium: 16px;

// Spacing
$spacing-zero: 0px;
$spacing-xs: 10px;
$spacing-sm: 12px;
$spacing-sm-m: 14px;
$spacing-md: 16px;
$spacing-md-m: 18px;
